// @ts-strict-ignore

import { ItemSelectFormComponent, ItemSelectIF } from '@/formbuilder/ItemSelectFormComponent.atom';
import { ValueWithUnitsFormComponent, ValueWithUnitsIF } from '@/formbuilder/ValueWithUnitsFormComponent';
import { ValueWithSymbolFormComponent, ValueWithSymbolIF } from '@/formbuilder/ValueWithSymbolFormComponent';
import { SearchTitleFormComponent, SearchTitleIF } from '@/formbuilder/SearchTitleFormComponent';
import { LabelFormComponent, LabelIF } from '@/formbuilder/LabelFormComponent.atom';
import { PatternFormComponent, PatternIF } from '@/formbuilder/PatternFormComponent';
import { SelectFormComponent, SelectIF } from '@/formbuilder/SelectFormComponent';
import { RadioButtonGroupFormComponent, RadioButtonsIF } from '@/formbuilder/RadioButtonGroupFormComponent.atom';
import { CheckboxFormComponent, CheckboxIF } from '@/formbuilder/CheckboxFormComponent.atom';
import { IconSelectFormComponent, IconSelectIF } from '@/formbuilder/IconSelectFormComponent.atom';
import { OperatorSelectFormComponent, OperatorSelectIF } from '@/formbuilder/OperatorSelectFormComponent.atom';
import { TextFieldFormComponent, TextFieldIF } from '@/formbuilder/TextFieldFormComponent.atom';
import { FormControlJsonIF, JsonTextAreaFormComponent } from '@/formbuilder/JsonTextAreaFormComponent.atom';
import { ErrorMessageFormComponent, ErrorMessageIF } from '@/formbuilder/ErrorMessageFormComponent.atom';
import { PasswordCheckFormComponent, PasswordCheckIF } from '@/formbuilder/PasswordCheckFormComponent.atom';
import { PasswordGroupFormComponent, PasswordGroupIF } from '@/formbuilder/PasswordGroupFormComponent';
import { SelectIdentityFormComponent, SelectIdentityProps } from '@/formbuilder/SelectIdentityFormComponent.atom';
import { TimeZoneSelectorFormComponent, TimeZoneSelectorIF } from '@/formbuilder/TimeZoneSelectorFormComponent.atom';
import { ClickableLinkFormComponent, ClickableLinkIF } from '@/formbuilder/ClickableLinkFormComponent.atom';
import { ToolStore } from '@/core/AdvancedParameters.atom';
import { CheckboxTableFormComponent, CheckboxTableIF } from '@/formbuilder/CheckboxTableFormComponent.atom';
import { ButtonGroupFormComponent, ButtonGroupIF } from '@/formbuilder/ButtonGroupFormComponent';
import { SelectUnitFormComponent, SelectUnitIF } from '@/formbuilder/SelectUnitFormComponent.atom';
import { FileDropperFormComponent, FileDropperIF } from '@/formbuilder/FileDropperFormComponent.atom';
import {
  CompositesSelectorFormComponent,
  CompositesSelectorIF,
} from '@/formbuilder/CompositeLogicSelectorFormComponent.molecule';
import { MaxCapsuleDurationFormComponent, MaxCapsuleDurationIF } from '@/formbuilder/MaxCapsuleDurationFormComponent';
import { IconFormComponent, IconIF } from '@/formbuilder/IconFormComponent.atom';
import { CapsuleInputFormComponent, CapsuleInputIF } from '@/formbuilder/CapsuleInputFormComponent.molecule';
import { InputTableFormComponent, InputTableIF } from '@/formbuilder/InputTableFormComponent.molecule';
import { StatisticSelectorFormComponent, StatisticSelectorIF } from '@/formbuilder/StatisticSelectorFormComponent.atom';
import { ColorPickerFormComponent, ColorPickerIF } from '@/formbuilder/ColorPickerFormComponent.molecule';
import { PriorityPickerFormComponent, PriorityPickerIF } from '@/formbuilder/PriorityPickerFormComponent.molecule';
import { FormulaVariablesFormComponent, FormulaVariablesIF } from '@/formbuilder/FormulaVariablesFormComponent';
import { FormulaEditorFormComponent, FormulaEditorIF } from '@/formbuilder/FormulaEditorFormComponent';
import { DisplayOnlyFormElementWrapper } from '@/formbuilder/formBuildingBlocks';
import { ImageSelectFormComponent, ImageSelectIF } from '@/formbuilder/ImageSelectFormComponent';
import { CapsuleGroupFormComponent, CapsuleGroupIF } from '@/formbuilder/CapsuleGroupFormComponent';
import { SearchWidgetFormComponent, SearchWidgetIF } from '@/formbuilder/SearchWidgetFormComponent';
import { ScatterPlotRegionFormComponent, ScatterPlotRegionIF } from '@/formbuilder/ScatterPlotRegionFormComponent';
import { DateTimeRangeFormComponent, DateTimeRangeIF } from '@/formbuilder/DateTimeRangeFormComponent';
import { DateTimeEntryFormComponent, DateTimeEntryIF } from '@/formbuilder/DateTimeEntryFormComponent';
import React from 'react';
import { ValueBinsFormComponent, ValueBinsIF } from '@/formbuilder/ValueBinsFormComponent';
import { ButtonWithPopoverFormComponent, ButtonWithPopoverIF } from '@/formbuilder/ButtonWithPopoverFormComponent';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import {
  SelectItemSearchFormComponent,
  SelectItemSearchFormComponentProps,
} from '@/formbuilder/SelectItemSearchFormComponent';
import { SelectFolderFormComponent, SelectFolderProps } from '@/formbuilder/SelectFolderFormComponent';
import { SelectWorkbookFormComponent, SelectWorkbenchProps } from '@/formbuilder/SelectWorkbookFormComponent';
import { CopyableInputFormComponent, CopyableInputProps } from '@/formbuilder/CopyableInputFormComponent.molecule';
import { ScheduleFormComponent, ScheduleFormComponentProps } from '@/formbuilder/ScheduleFormComponent';
import { DurationFormComponent, DurationFormComponentProps } from '@/formbuilder/DurationFormComponent';
import {
  InputWithLinkedButtonsFormComponent,
  InputWithLinkedButtonsProps,
} from '@/formbuilder/InputWithLinkedButtonsFormComponent';
import { DatePickerFormComponent, DatePickerFormComponentProps } from '@/formbuilder/DatePickerFormComponent.atom';
import { ButtonVariant } from '@seeqdev/qomponents/dist/Button/Button.types';

export const FORM_ERROR = 'Error';
export const FORM_WARNING = 'Warning';

export const supportedComponents = {
  CheckboxFormComponent,
  ErrorMessageFormComponent,
  TextFieldFormComponent,
  JsonTextAreaFormComponent,
  IconSelectFormComponent,
  ItemSelectFormComponent,
  LabelFormComponent,
  OperatorSelectFormComponent,
  RadioButtonGroupFormComponent,
  SearchTitleFormComponent,
  ValueWithUnitsFormComponent,
  TimeZoneSelectorFormComponent,
  ClickableLinkFormComponent,
  CheckboxTableFormComponent,
  SelectUnitFormComponent,
  FileDropperFormComponent,
  CompositesSelectorFormComponent,
  MaxCapsuleDurationFormComponent,
  IconFormComponent,
  CapsuleInputFormComponent,
  InputTableFormComponent,
  StatisticSelectorFormComponent,
  ColorPickerFormComponent,
  PriorityPickerFormComponent,
  ValueWithSymbolFormComponent,
  PatternFormComponent,
  SelectFormComponent,
  ButtonGroupFormComponent,
  DisplayOnlyFormElementWrapper,
  ImageSelectFormComponent,
  PasswordCheckFormComponent,
  PasswordGroupFormComponent,
  FormulaVariablesFormComponent,
  FormulaEditorFormComponent,
  CapsuleGroupFormComponent,
  SearchWidgetFormComponent,
  SelectIdentityFormComponent,
  ScatterPlotRegionFormComponent,
  DateTimeRangeFormComponent,
  DateTimeEntryFormComponent,
  ValueBinsFormComponent,
  ButtonWithPopoverFormComponent,
  SelectItemSearchFormComponent,
  SelectFolderFormComponent,
  SelectWorkbookFormComponent,
  CopyableInputFormComponent,
  ScheduleFormComponent,
  DurationFormComponent,
  InputWithLinkedButtonsFormComponent,
  DatePickerFormComponent,
};

export interface ContinueButtonProps {
  /** This only applies to the "Save and Continue" Form: the label for the "submit and continue" button **/
  continueButtonLabel?: string;
  /** This only applies to the "Save and Continue" Form: if the "extra" submit button is enabled or not **/
  continueButtonEnabled?: boolean;
  /** This only applies to the "Save and Continue" Form: useful to control the display of the "extra" submit button **/
  continueButtonVariant?: ButtonVariant;
  /** This only applies to the "Save and Continue" Form: callback function for the "extra" submit button **/
  continueButtonOnSubmit?: (values: any) => void;
  /** This only applies to the "Save and Continue" Form: indicates where the "extra" submit button should be placed
   *  (relative to the "original" submit button **/
  continueButtonPosition?: 'left' | 'right';
  /** This only applies to the "Save and Continue" Form: optional extraClassNames for the "extra" submit button**/
  continueButtonExtraClassNames?: string;
}

interface BaseFormElement {
  name: string;
  component: string;
  includeIf?: boolean;
  displayNumber?: boolean;
  // number will be generated by FormBuilder - if you want a number to display set displayNumber to true
  number?: number;
  testId?: string;
  // Setting this to true enables forms to use the form state only. This is for forms that are not fast-followed (like
  // the Edit User Modal)
  skipStore?: boolean;
  extraClassNames?: string;
  // if the field comes with a default value be sure to set this flag to true (once you have the default value) - to
  // ensure that form errors will display properly
  defaultProvided?: boolean;
  fieldNames?: string[];
  customStyles?: any;
  key?: any;
}

export interface ValidatingFormComponent<T> extends BaseFormElement {
  value?: T;
  translationArgument?: Record<string, any>;
  onChange?: (value: NonNullable<T>) => void;
  // return true if invalid, false if valid
  validation?: (value: T) => boolean;
  // true to use default and custom validation, false to only use custom validation
  extendValidation?: boolean;
  // number of milliseconds to wait before showing a tooltip on-hover
  tooltipDelay?: number;
  // text to display on icon tooltip
  tooltip?: string;
  // where to place tooltip
  tooltipPlacement?: TooltipPosition;
  onTooltipClick?: () => void;
  // text to display if validation fails
  customErrorText?: string;
  // associated error params
  customErrorParams?: Record<string, any>;
  label?: string | React.ReactNode;
  required?: boolean;
  wrapperClassNames?: string;
  extraClassNames?: string;
}

export interface FormRow extends BaseFormElement {
  component: 'FormRow';
  components: FormElement[];
}

export interface RemovableFormRow extends BaseFormElement {
  component: 'RemovableFormRow';
  components: FormElement[];
  hideRemoveIcon?: boolean;
  removeAction: () => void;
  wrapperClassNames?: string;
}

export interface FormGroup extends BaseFormElement {
  component: 'FormGroup';
  components: FormElement[];
  showBracket?: boolean;
  showScroll?: boolean;
}

export interface FormGroupWithTitle extends BaseFormElement {
  component: 'FormGroupWithTitle';
  components: FormElement[];
  showBracket?: boolean;
  showScroll?: boolean;
  showTitle?: boolean;
  title?: string;
  titleLink?: string;
  titleLinkAction?: () => void;
  additionalTitleLink?: string;
  additionalTitleLinkAction?: () => void;
}

export interface AdvancedFormGroup extends BaseFormElement {
  component: 'AdvancedFormGroup';
  components: FormElement[];
  toolStore: ToolStore;
  toolName: string;
  toolId: string;
  alternateName?: string;
  iconStyle?: string;
}

export interface RemovableFormGroup extends BaseFormElement {
  component: 'RemovableFormGroup';
  components: FormElement[];
  hideIcon?: boolean;
  iconAction: () => void;
  iconHasMargin?: boolean;
  iconTooltip?: string;
}

export interface CollapsibleFormGroup extends BaseFormElement {
  component: 'CollapsibleFormGroup';
  components: FormElement[];
  deleteIconAction: () => void;
  hideDeleteIcon?: boolean;
  extraClassNames?: string;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  label: string;
  isLast?: boolean;
}

/**
 * this wrapper allows adding any child to a form and render it within form groups, rows, or blocks
 * it can only support "display only" children; no validation will be applied.
 * a typical use case for this are tables that only display information, or buttons that require no validation
 */
export interface DisplayOnlyFormElementWrapperIF extends BaseFormElement {
  component: 'DisplayOnlyFormElementWrapper';
  children: React.ReactNode;
}

export type FormElement =
  | FormGroup
  | FormGroupWithTitle
  | FormRow
  | AdvancedFormGroup
  | ItemSelectIF
  | ValueWithUnitsIF
  | SearchTitleIF
  | LabelIF
  | IconIF
  | RadioButtonsIF
  | CheckboxIF
  | IconSelectIF
  | CompositesSelectorIF
  | OperatorSelectIF
  | TextFieldIF
  | FormControlJsonIF
  | ErrorMessageIF
  | TimeZoneSelectorIF
  | CheckboxTableIF
  | ClickableLinkIF
  | SelectUnitIF
  | FileDropperIF
  | MaxCapsuleDurationIF
  | CapsuleInputIF
  | InputTableIF
  | RemovableFormGroup
  | RemovableFormRow
  | StatisticSelectorIF
  | ColorPickerIF
  | PriorityPickerIF
  | ValueWithSymbolIF
  | PatternIF
  | SelectIF
  | ButtonGroupIF
  | ImageSelectIF
  | PasswordCheckIF
  | FormulaVariablesIF
  | FormulaEditorIF
  | PasswordGroupIF
  | DisplayOnlyFormElementWrapperIF
  | CapsuleGroupIF
  | SearchWidgetIF
  | SelectIdentityProps
  | ScatterPlotRegionIF
  | DateTimeRangeIF
  | DateTimeEntryIF
  | ValueBinsIF
  | ButtonWithPopoverIF
  | SelectItemSearchFormComponentProps
  | SelectFolderProps
  | SelectWorkbenchProps
  | CopyableInputProps
  | ScheduleFormComponentProps
  | DurationFormComponentProps
  | InputWithLinkedButtonsProps
  | CollapsibleFormGroup
  | DatePickerFormComponentProps;
