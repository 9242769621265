/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202412042329-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The ordered path of items that led to the error. For example, it could contain an Analysis, then a relationship saying the Analysis has a Worksheet, then said Worksheet, then a relationship saying this worksheet has a Signal in its Journal, and then the id field in this error would be said Signal.
 */
export interface LockErrorPathOutputV1 {
    /**
     * The relationship the item has to its direct child in the path
     */
    "childRelationship"?: ChildRelationshipEnum;
    /**
     * The id of the item in the path
     */
    "id"?: string;
    "item"?: models.BaseOutput;
}

export enum ChildRelationshipEnum {
    CURRENTWORKSTEPINAWORKSHEET = 'CURRENT_WORKSTEP_IN_A_WORKSHEET' as any,
    WORKSHEET = 'WORKSHEET' as any,
    SCOPEDTO = 'SCOPED_TO' as any,
    HASINTEREST = 'HAS_INTEREST' as any,
    INTERESTEDIN = 'INTERESTED_IN' as any,
    MONITOREDBY = 'MONITORED_BY' as any,
    MONITORS = 'MONITORS' as any,
    REFERENCEDBYJOURNALINWORKSHEET = 'REFERENCED_BY_JOURNAL_IN_WORKSHEET' as any,
    REFERENCEDBYWORKSTEP = 'REFERENCED_BY_WORKSTEP' as any,
    POSSIBLECHILD = 'POSSIBLE_CHILD' as any,
    NOREADPERMISSION = 'NO_READ_PERMISSION' as any,
    NOWRITEPERMISSION = 'NO_WRITE_PERMISSION' as any,
    NOMANAGEPERMISSION = 'NO_MANAGE_PERMISSION' as any,
    HASPARAMETER = 'HAS_PARAMETER' as any
}
